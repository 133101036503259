import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { useId } from "@reach/auto-id";
import { VariantProps } from "@stitches/react";
import { CSS, useThemeOverrides } from "@truepill/capsule-utils";
import { styled } from "@truepill/capsule-utils";
import React from "react";
import { Check } from "react-feather";

import { Spacer } from "../spacer/Spacer";
import { Text } from "../text/Text";

export type CheckboxSize = "sm" | "md";

const StyledCheckbox = styled(CheckboxPrimitive.Root, {
  all: "unset",
  boxSizing: "border-box",
  userSelect: "none",
  "&::before": {
    boxSizing: "border-box",
  },
  "&::after": {
    boxSizing: "border-box",
  },
  cursor: "pointer",
  alignItems: "center",
  appearance: "none",
  display: "flex",
  justifyContent: "center",
  margin: "0",
  outline: "none",
  padding: "0",
  borderRadius: "$xs",
  WebkitTapHighlightColor: "rgba(0,0,0,0)",

  overflow: "hidden",

  "&:focus": {
    outline: "-webkit-focus-ring-color auto 1px",
    outlineOffset: "3px",
  },
  "&:disabled": {
    cursor: "default",
    opacity: 0.5,
  },

  variants: {
    size: {
      sm: {
        size: "$md",
      },
      md: {
        size: "$lg",
      },
    },
    state: {
      default: {
        boxShadow: "inset 0px 0px 0px 2px $colors$gray-500",
      },
      error: {
        boxShadow: "inset 0px 0px 0px 2px $colors$functional-error-dark",
        backgroundColor: "$functional-error-light",
      },
    },
  },
  defaultVariants: {
    state: "default",
    size: "md",
  },
});

const Wrapper = styled("div", {
  display: "inline-flex",
  alignItems: "center",
});

const StyledIndicator = styled(CheckboxPrimitive.Indicator, {
  color: "$typography-white",
  size: "100%",
  variants: {
    size: {
      sm: {
        padding: "1px",
      },
      md: {
        padding: "4px",
      },
    },
    state: {
      default: {
        backgroundColor: "$functional-info-dark",
      },
      error: {
        boxShadow: "inset 0px 0px 0px 2px $colors$functional-error-dark",
        backgroundColor: "$functional-error-dark",
      },
    },
  },
  defaultVariants: {
    size: "md",
    state: "default",
  },
});

const LabelWrapper = styled("div", {
  display: "flex",
});

type CheckboxPrimitiveProps = React.ComponentProps<
  typeof CheckboxPrimitive.Root
>;
type CheckboxVariants = VariantProps<typeof StyledCheckbox>;
type CheckboxProps = CheckboxPrimitiveProps &
  CheckboxVariants & { css?: CSS; indicatorCss?: CSS } & {
    label?: string;
  };

/**
 * Allows users to select one or more items from a set.
 */
export const Checkbox = React.forwardRef<
  React.ElementRef<typeof StyledCheckbox>,
  CheckboxProps
>(({ label, size = "md", state, css, indicatorCss, id, ...rest }, ref) => {
  const overrides = useThemeOverrides("checkbox");
  const indicatorOverrides = useThemeOverrides("checkboxIndicator");
  const uniqueId = useId(id);

  return (
    <Wrapper>
      <StyledCheckbox
        {...rest}
        id={uniqueId}
        css={{ ...(overrides && overrides), ...css }}
        state={state}
        size={size}
        ref={ref}
      >
        <StyledIndicator
          size={size}
          state={state}
          css={{
            ...(indicatorOverrides && indicatorOverrides),
            ...indicatorCss,
          }}
        >
          <Check
            width="100%"
            height="100%"
            strokeWidth={2.5}
            aria-hidden
            focusable={false}
          />
        </StyledIndicator>
      </StyledCheckbox>
      {label && (
        <LabelWrapper>
          <Spacer size={size === "md" ? "md" : "sm"} />
          <Text as="label" htmlFor={uniqueId}>
            {label}
          </Text>
        </LabelWrapper>
      )}
    </Wrapper>
  );
});
