import type { CSS as StitchesCSS } from "@stitches/react";
import { createStitches } from "@stitches/react";

const shadowHSL = "222, 52%, 19%";

const baseTheme = {
  fonts: {
    base: "Lato, Helvetica, Arial, sans-serif",
    header: "Lato, Helvetica, Arial, sans-serif",
  },
  colors: {
    "primary-100": "hsla(203, 100%, 98%, 1)",
    "primary-300": "hsla(207, 100%, 93%, 1)",
    "primary-500": "hsla(206, 100%, 41%, 1)",
    "primary-700": "hsla(205, 96%, 26%, 1)",
    "primary-900": "hsla(205, 100%, 12%, 1)",

    "secondary-100": "hsla(324, 100%, 99%, 1)",
    "secondary-300": "hsla(330, 100%, 93%, 1)",
    "secondary-500": "hsla(330, 76%, 67%, 1)",
    "secondary-700": "hsla(328, 73%, 49%, 1)",
    "secondary-900": "hsla(328, 100%, 26%, 1)",

    "gray-100": "hsla(240, 20%, 98%, 1)",
    "gray-300": "hsla(214, 19%, 93%, 1)",
    "gray-500": "hsla(218, 18%, 68%, 1)",
    "gray-700": "hsla(219, 18%, 37%, 1)",
    "gray-900": "hsla(213, 19%, 9%, 1)",

    white: "hsla(0, 0%, 100%, 1)",
    black: "hsla(210, 17%, 7%, 1)",

    "functional-info-dark": "hsla(206, 99%, 40%, 1)",
    "functional-info-light": "hsla(204, 100%, 97%, 1)",
    "functional-error-dark": "hsla(360, 73%, 48%, 1)",
    "functional-error-light": "hsla(0, 100%, 97%, 1)",
    "functional-success-dark": "hsla(125, 74%, 30%, 1)",
    "functional-success-light": "hsla(127, 100%, 98%, 1)",
    "functional-warning-dark": "hsla(28, 96%, 35%, 1)",
    "functional-warning-light": "hsla(37, 100%, 95%, 1)",

    "typography-white": "$white",
    "typography-light": "hsla(214, 19%, 93%, 1)",
    "typography-medium": "hsla(224, 8%, 35%, 1)",
    "typography-dark": "hsla(213, 19%, 9%, 1)",
  },
  space: {
    none: 0,
    "4xs": "0.125rem",
    "2xs": "0.25rem",
    xs: "0.5rem",
    sm: "0.75rem",
    md: "1rem",
    lg: "1.5rem",
    xl: "2rem",
    "2xl": "3rem",
    "3xl": "4rem",
    "4xl": "5rem",
    "5xl": "6rem",
    "6xl": "8rem",
    "7xl": "10rem",
    "8xl": "12.5rem",
    "9xl": "18.5rem",
  },
  sizes: {
    none: 0,
    "4xs": "0.125rem",
    "2xs": "0.25rem",
    xs: "0.5rem",
    sm: "0.75rem",
    md: "1rem",
    lg: "1.5rem",
    xl: "2rem",
    "2xl": "3rem",
    "3xl": "4rem",
    "4xl": "5rem",
    "5xl": "6rem",
    "6xl": "8rem",
    "7xl": "10rem",
    "8xl": "12.5rem",
    "9xl": "18.5rem",
  },
  radii: {
    "2xs": "2px",
    xs: "4px",
    sm: "8px",
    md: "16px",
    xl: "99px",
  },
  shadows: {
    none: 0,
    sm: `0 4px 4px hsla(${shadowHSL}, 0.25), 0 1px 2px hsla(${shadowHSL}, 0.07)`,
    md: `0 6px 8px -6px hsla(${shadowHSL}, 0.12), 0 8px 16px -6px hsla(${shadowHSL}, 0.08)`,
    lg: `0 4px 4px hsla(${shadowHSL}, 0.25), 0 4px 6px -2px hsla(${shadowHSL}, 0.06), 0 10px 15px -3px hsla(${shadowHSL}, 0.1)`,
    xl: `0 10px 10px -5px hsla(${shadowHSL}, 0.06), 0px 20px 25px -5px hsla(${shadowHSL}, 0.1)`,
    "2xl": `0 10px 10px -5px hsla(${shadowHSL}, 0.06), 0 20px 25px -5px hsla(${shadowHSL}, 0.1);`,
  },
  fontSizes: {
    xs: "0.5rem",
    sm: "0.75rem",
    md: "1rem",
    lg: "1.5rem",
    xl: "2rem",
  },
  lineHeights: {
    xs: "0.5rem",
    sm: "0.75rem",
    md: "1rem",
    lg: "1.5rem",
    xl: "2rem",
  },
};

const utils = {
  bx: (value: string) => ({
    borderLeft: value,
    borderRight: value,
  }),
  by: (value: string) => ({
    borderTop: value,
    borderBottom: value,
  }),
  size: (value: string) => ({
    height: value,
    width: value,
  }),
};

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  prefix: "cap",
  theme: baseTheme,
  media: {
    tablet: "(min-width: 720px)",
    desktop: "(min-width: 1280px)",
  },
  utils,
});

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export type CSS = StitchesCSS<typeof config>;
export type CapsuleTheme = RecursivePartial<typeof baseTheme>;
export type Sizes = keyof typeof baseTheme["sizes"];
