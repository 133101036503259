import { useId } from "@reach/auto-id";
import type * as Stitches from "@stitches/react";
import {
  config,
  css,
  styled,
  useThemeOverrides,
} from "@truepill/capsule-utils";
import type { ReactElement } from "react";
import * as React from "react";

import { ScreenReaderOnly } from "../../components/screen-reader-only/ScreenReaderOnly";
import type { InputType } from "../../types";
import { Text } from "../text/Text";

type InputState = "default" | "complete" | "error";
type Size = "sm" | "lg";

interface BaseProps {
  state?: InputState;
  subCopy?: string;
  helperText?: string;
  size?: Size;
  label?: string;
  css?: Stitches.CSS<typeof config>;
}

interface TextFieldProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "size">,
    BaseProps {
  type?: InputType;
}

interface TextFieldTextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    BaseProps {
  type: "textarea";
}

const IconWrapper = styled("div", {
  position: "absolute",
  display: "flex",
  alignItems: "center",
  top: 0,
  right: 0,
  height: "100%",
  marginRight: "0.75rem",
  pointerEvents: "none",
});

const StyledAlert = styled("svg", {
  width: "24px",
  height: "24px",
  color: "$functional-error-dark",
});

const StyledCheck = styled("svg", {
  display: "none",
  width: "24px",
  fill: "$functional-success-dark",
});

const baseTextFieldStyles = css({
  borderWidth: "0",
  boxShadow: "inset 0px 0px 0px 1px $colors$gray-700",
  background: "$white",
  padding: "0 1.75rem 0 0.75rem",
  borderRadius: "$sm",
  width: "100%",
  outline: "none",

  "&:focus": {
    borderColor: "transparent",
    boxShadow: "inset 0px 0px 0px 3px $colors$primary-500",
  },

  variants: {
    size: {
      sm: {
        height: "48px",
      },
      lg: {
        height: "56px",
      },
    },
    state: {
      default: {},
      error: {
        boxShadow: "inset 0px 0px 0px 1px $colors$functional-error-dark",
        background: "$functional-error-light",
        "&:focus": {
          boxShadow: "inset 0px 0px 0px 3px $colors$functional-error-dark",
        },
      },
      complete: {
        background: "$gray-100",
        borderColor: "$gray-500",
        "&:focus": {
          border: "$primary-500",
        },
      },
    },
  },
  defaultVariants: {
    state: "default",
    size: "lg",
  },
});

const StyledTextField = styled("input", baseTextFieldStyles);

const Wrapper = styled("div", {
  position: "relative",
  "&[data-disabled='true']": {
    opacity: 0.5,
  },

  "&[data-state='complete']": {
    [`${StyledCheck}`]: {
      display: "block",
    },
  },
  textarea: {
    paddingTop: "1rem",
    paddingBottom: "1rem",

    height: "auto",
  },
});

const HelperTextWrapper = styled("div", {
  display: "inline-flex",
  alignItems: "center",
  marginTop: "$xs",
});

const StyledHelperText = styled(Text, {
  display: "block",
  "&.error": {
    color: "$functional-error-dark",
    marginLeft: "0.125rem",
  },
});

const StyledSubCopy = styled(Text, {
  flexBasis: "100%",
  marginTop: "$xs",
  color: "$typography-medium",
  fontWeight: "normal",
});

const WrapperLabelRow = styled("div", {
  paddingBottom: "$sm",
});

const LabelRequiredWrapper = styled("span", {
  display: "flex",
  alignItems: "center",
  ".required": {
    color: "$functional-error-dark",
    marginLeft: "0.125rem",
  },
  ".field-title": {
    whiteSpace: "nowrap",
  },
});

const StyledWrapper = styled("div", {
  display: "inline-block",
  width: "100%",
});

/**
 * Allows users to enter and edit text. Also able to render a `<textfield />` by passing `type="textField"`
 */
export const TextField = React.forwardRef<
  any,
  TextFieldProps | TextFieldTextAreaProps
>(
  (
    {
      state = "default",
      size,
      label,
      required,
      subCopy,
      helperText,
      type = "text",
      css,
      ...rest
    },
    ref
  ): ReactElement => {
    const id = useId(rest.id);
    const uniqueId = useId(id);
    const overrides = useThemeOverrides("textField");

    return (
      <StyledWrapper>
        {label && (
          <WrapperLabelRow>
            <Text as="label" htmlFor={id} variant="body" bold>
              <LabelRequiredWrapper>
                <span className="field-title">{label}</span>
                {required && (
                  <>
                    <span className="required" aria-hidden>
                      &#42;
                    </span>
                    <ScreenReaderOnly>Required</ScreenReaderOnly>
                  </>
                )}
              </LabelRequiredWrapper>
              {subCopy && (
                <StyledSubCopy variant="body">{subCopy}</StyledSubCopy>
              )}
            </Text>
          </WrapperLabelRow>
        )}
        <Wrapper data-state={state} data-disabled={rest.disabled}>
          <StyledTextField
            size={size}
            required={required}
            aria-invalid={state === "error"}
            aria-describedby={helperText ? uniqueId : undefined}
            state={state}
            id={id}
            as={type === "textarea" ? "textarea" : "input"}
            ref={ref}
            css={{ ...(overrides && overrides), ...css }}
            type={type !== "textarea" ? type : undefined}
            {...rest}
          />
          <IconWrapper>
            <StyledCheck xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </StyledCheck>
          </IconWrapper>
        </Wrapper>
        {helperText && (
          <HelperTextWrapper>
            {state === "error" && (
              <StyledAlert
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.2898 3.85996L1.81978 18C1.64514 18.3024 1.55274 18.6453 1.55177 18.9945C1.55079 19.3437 1.64127 19.6871 1.8142 19.9905C1.98714 20.2939 2.2365 20.5467 2.53748 20.7238C2.83847 20.9009 3.18058 20.9961 3.52978 21H20.4698C20.819 20.9961 21.1611 20.9009 21.4621 20.7238C21.7631 20.5467 22.0124 20.2939 22.1854 19.9905C22.3583 19.6871 22.4488 19.3437 22.4478 18.9945C22.4468 18.6453 22.3544 18.3024 22.1798 18L13.7098 3.85996C13.5315 3.56607 13.2805 3.32308 12.981 3.15444C12.6814 2.98581 12.3435 2.89722 11.9998 2.89722C11.656 2.89722 11.3181 2.98581 11.0186 3.15444C10.7191 3.32308 10.468 3.56607 10.2898 3.85996Z"
                  fill="currentColor"
                />
                <path
                  d="M12 9V13M12 17H12.01"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </StyledAlert>
            )}
            <StyledHelperText className={state} variant="body-sm" id={uniqueId}>
              {helperText}
            </StyledHelperText>
          </HelperTextWrapper>
        )}
      </StyledWrapper>
    );
  }
);
