import * as AccordionPrimitive from "@radix-ui/react-accordion";
import type * as Radix from "@radix-ui/react-primitive";
import { keyframes, styled, useThemeOverrides } from "@truepill/capsule-utils";
import { motion } from "framer-motion";
import * as React from "react";
import { ChevronDown } from "react-feather";

const close = keyframes({
  from: { height: "var(--radix-accordion-content-height)" },
  to: { height: 0 },
});

const StyledRoot = styled(AccordionPrimitive.Root, {});

const StyledChevron = styled(motion(ChevronDown), {
  stroke: "currentColor",
  marginLeft: "1.5rem",
  minWidth: "24px",
  transition: "transform 200ms cubic-bezier(0.87, 0, 0.13, 1)",
  "[data-state=open] &": { transform: "rotate(180deg)" },
});

const StyledHeader = styled(AccordionPrimitive.Header, {
  all: "unset",
  display: "flex",
});

const StyledTrigger = styled(AccordionPrimitive.Trigger, {
  fontFamily: "$base",
  border: "none",
  backgroundColor: "transparent",
  cursor: "pointer",
  padding: "$md",
  flex: 1,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "space-between",
  textAlign: "inherit",
});

const StyledItem = styled(AccordionPrimitive.Item, {
  all: "unset",
  overflow: "hidden",
});

const StyledContent = styled(AccordionPrimitive.Content, {
  overflow: "hidden",
  '&[data-state="closed"]': { animation: `${close} 200ms ease-out` },
});

/**
 * Toggles the collapsed state of its associated item. Must be a child of an `<AccordionItem />` component.
 */
const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof StyledTrigger>,
  { withChevron?: boolean } & React.ComponentProps<typeof StyledTrigger>
>(({ children, withChevron = true, css, ...props }, forwardedRef) => {
  const overrides = useThemeOverrides("accordionTrigger");
  return (
    <StyledHeader>
      <StyledTrigger
        {...props}
        css={{ ...(overrides && overrides), ...css }}
        ref={forwardedRef}
      >
        {children}
        {withChevron && (
          <StyledChevron
            data-testid="cap-accordion-chevron"
            aria-hidden
            size={24}
          />
        )}
      </StyledTrigger>
    </StyledHeader>
  );
});

/**
 * Contains the collapsible content for an item. Must be a child of an `<AccordionItem />` component.
 */
const AccordionContent = React.forwardRef<
  React.ElementRef<typeof StyledContent>,
  React.ComponentProps<typeof StyledContent>
>(({ children, css, ...props }, forwardedRef) => {
  const overrides = useThemeOverrides("accordionContent");
  return (
    <StyledContent
      {...props}
      css={{ ...(overrides && overrides), ...css }}
      ref={forwardedRef}
    >
      <motion.div
        animate={{ y: 0, "--opacity": 1, height: "auto" } as any}
        initial={{ y: -50, "--opacity": 0, height: 0 } as any}
        transition={{
          type: "spring",
          duration: 0.3,
        }}
      >
        {children}
      </motion.div>
    </StyledContent>
  );
});

/**
 * Wrapper for all the parts of an accordion.
 */
const Accordion = React.forwardRef<
  React.ElementRef<typeof StyledRoot>,
  Radix.ComponentPropsWithoutRef<typeof StyledRoot>
>(({ css, ...props }, forwardedRef) => {
  const overrides = useThemeOverrides("accordion");

  return (
    <StyledRoot
      css={{ ...(overrides && overrides), ...css }}
      {...props}
      ref={forwardedRef}
    />
  );
});

/**
 * Contains all the parts of a collapsible section. Must be a child of the `<Accordion />` component.
 */
const AccordionItem = React.forwardRef<
  React.ElementRef<typeof StyledItem>,
  React.ComponentProps<typeof StyledItem>
>(({ css, ...props }, forwardedRef) => {
  const overrides = useThemeOverrides("accordionItem");

  return (
    <StyledItem
      css={{ ...(overrides && overrides), ...css }}
      {...props}
      ref={forwardedRef}
    />
  );
});

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
