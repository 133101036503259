import { Portal } from "@reach/portal";
import {
  Position as ReachUiPosition,
  TooltipPopup as ReachUiTooltip,
  useTooltip,
} from "@reach/tooltip";
import { CSS, styled, useThemeOverrides } from "@truepill/capsule-utils";
import { AnimatePresence, motion } from "framer-motion";
import React, { HTMLAttributes, ReactElement, useMemo } from "react";

import {
  bottom,
  calculateArrowPosition,
  left,
  right,
  top,
} from "./tooltip-functions";

type variant =
  | "white"
  | "brand-mid"
  | "primary"
  | "brand-dark"
  | "brand-light"
  | "secondary";
export type TooltipPosition = "top" | "bottom" | "left" | "right";

interface TooltipProps extends HTMLAttributes<HTMLDivElement> {
  /** Determine the color variant being shown. Defaults to `primary` */
  variant?: variant;
  /** Label to show on hover tooltip text */
  label?: string | ReactElement;
  /** Determines the position of the Tooltip  */
  position?: TooltipPosition;
  /** In cases we want the screen reader user to know both the content in the tooltip, but also the content in the trigger. For screen reader users, the only content announced to them is whatever is in the tooltip. For these cases, use the aria-label prop. */
  ariaLabel?: string;
  /** CSS override for the arrow element of the tooltip */
  arrowCss?: CSS;
  /** CSS override for the text container */
  css?: CSS;
}

const MotionTooltip = styled(motion(ReachUiTooltip), {
  all: "unset",
  display: "inline-block",
  zIndex: 1,
  borderRadius: "$sm",
  fontSize: "0.875rem",
  fontFamily: "$base",
  position: "absolute",
  padding: "$md",
  lineHeight: "20px",
  maxWidth: "300px",
  overflowWrap: "break-word",
  wordWrap: "break-word",
  border: "none",
  boxShadow: "$md",
  variants: {
    variant: {
      white: {
        color: "$typography-dark",
        backgroundColor: "$white",
      },
      "brand-light": {
        color: "$typography-dark",
        backgroundColor: "$primary-300",
      },
      "brand-mid": {
        color: "$white",
        backgroundColor: "$primary-500",
      },
      primary: {
        color: "$white",
        backgroundColor: "$primary-700",
      },
      "brand-dark": {
        color: "$white",
        backgroundColor: "$primary-900",
      },
      secondary: {
        color: "$white",
        backgroundColor: "$secondary-700",
      },
    },
  },
});

const MotionArrow = styled(motion.div, {
  $$arrowBorderWidth: "10px",
  $$arrowBorderStyle: "solid",
  $$transparentBorder: "$$arrowBorderWidth $$arrowBorderStyle transparent",
  position: "absolute",
  variants: {
    variant: {
      white: {
        borderColor: "$primary-100",
      },
      "brand-light": {
        borderColor: "$primary-300",
      },
      "brand-mid": {
        borderColor: "$primary-500",
      },
      primary: {
        borderColor: "$primary-700",
      },
      "brand-dark": {
        borderColor: "$primary-900",
      },
      secondary: {
        borderColor: "$secondary-700",
      },
    },
    position: {
      top: {
        borderTopStyle: "$$arrowBorderStyle",
        borderTopWidth: "$$arrowBorderWidth",
        bx: "$$transparentBorder !important",
      },
      bottom: {
        borderBottomStyle: "$$arrowBorderStyle",
        borderBottomWidth: "$$arrowBorderWidth",
        bx: "$$transparentBorder !important",
      },
      right: {
        borderRightStyle: "$$arrowBorderStyle",
        borderRightWidth: "$$arrowBorderWidth",
        by: "$$transparentBorder !important",
      },
      left: {
        borderLeftStyle: "$$arrowBorderStyle",
        borderLeftWidth: "$$arrowBorderWidth",
        by: "$$transparentBorder !important",
      },
    },
  },
});

const Box = React.forwardRef<HTMLSpanElement>((props, ref) => {
  return <span ref={ref} {...props} />;
});

/**
 * A Tooltip component.
 */
export const Tooltip = React.forwardRef<HTMLDivElement, TooltipProps>(
  (
    {
      children,
      ariaLabel,
      label,
      variant = "primary",
      position = "bottom",
      arrowCss,
      css,
    },
    ref
  ) => {
    const [trigger, tooltip] = useTooltip();
    const { isVisible, triggerRect } = tooltip;
    const arrowPosition = useMemo(
      () => calculateArrowPosition(triggerRect, position),
      [triggerRect, position]
    );

    const positionFunctions: Record<TooltipPosition, ReachUiPosition> = {
      bottom,
      top,
      left,
      right,
    };

    const animationOffset = 10;
    const animationCoordinates: { x: number; y: number } = {
      x:
        position === "left"
          ? -animationOffset
          : position === "right"
          ? animationOffset
          : 0,
      y:
        position === "top"
          ? -animationOffset
          : position === "bottom"
          ? animationOffset
          : 0,
    };
    const animParams = {
      initial: { opacity: 0, ...animationCoordinates },
      animate: {
        opacity: 1,
        x: 0,
        y: 0,
      },
      exit: {
        opacity: 0,
        ...animationCoordinates,
      },
      transition: { type: "tween", duration: 0.2 },
    };

    const arrowOverrides = useThemeOverrides("tooltipArrow");
    const tooltipOverrides = useThemeOverrides("tooltip");

    return (
      <React.Fragment>
        <Box {...trigger}>{children}</Box>
        <AnimatePresence>
          {isVisible && (
            <React.Fragment>
              <Portal key="motionPortal">
                <MotionArrow
                  {...animParams}
                  position={position}
                  variant={variant}
                  css={{
                    ...arrowPosition,
                    ...arrowOverrides,
                    ...arrowCss,
                  }}
                />
              </Portal>
              <MotionTooltip
                {...tooltip}
                {...animParams}
                label={label}
                aria-label={ariaLabel}
                variant={variant}
                position={positionFunctions[position]}
                ref={ref}
                key="motionTooltip"
                css={{
                  ...tooltipOverrides,
                  ...css,
                }}
              />
            </React.Fragment>
          )}
        </AnimatePresence>
      </React.Fragment>
    );
  }
);
