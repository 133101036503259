import {
  CSS,
  keyframes,
  styled,
  useThemeOverrides,
} from "@truepill/capsule-utils";
import React from "react";

import { ScreenReaderOnly } from "../screen-reader-only/ScreenReaderOnly";

type SpinnerSize = "sm" | "md" | "lg" | "auto";
type ColorVariants = "primary" | "white";

interface LoadingSpinnerProps
  extends React.ComponentPropsWithoutRef<typeof Spinner> {
  /**
   * size of the spinner
   */
  size?: SpinnerSize;
  /**
   * Variant/Color of the spinner
   */
  variant?: ColorVariants;
  /**
   * CSS overrides for the component
   */
  CSS?: CSS;
}

const SpinnerAnimation = keyframes({
  "0%": { transform: "rotate(0deg)" },
  "100%": { transform: "rotate(360deg)" },
});

const Spinner = styled("div", {
  position: "relative",
  display: "inline-block",
  width: "80px",
  height: "80px",
  boxSizing: "content-box",
  variants: {
    size: {
      sm: {
        size: "120px",
        div: {
          borderWidth: "15px",
        },
      },
      md: {
        size: "160px",
        div: {
          borderWidth: "20px",
        },
      },
      lg: {
        size: "240px",
        div: {
          borderWidth: "25px",
        },
      },
      auto: {
        size: "100%",
        div: {
          borderWidth: "15px",
        },
      },
    },
  },
  defaultVariants: {
    size: "md",
  },
  "[data-spinner-background]": {
    animationDelay: "-0.45s",
    borderColor: "rgba(15, 18, 21, 0.05)",
  },
  "div:nth-child(2)": {
    animationDelay: "-0.3s",
  },
  "div:nth-child(3)": {
    animationDelay: "-0.15s",
  },
});

const InnerLine = styled("div", {
  display: "block",
  position: "absolute",
  width: "64px",
  height: "64px",
  borderStyle: "solid",
  borderRadius: "50%",
  animation: `${SpinnerAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite`,
  variants: {
    variant: {
      primary: {
        borderColor: "$primary-700 transparent transparent transparent",
      },
      white: {
        borderColor: "$white transparent transparent transparent",
      },
    },
    size: {
      sm: {
        size: "120px",
      },
      md: {
        size: "160px",
      },
      lg: {
        size: "240px",
      },
      auto: {
        size: "100%",
      },
    },
  },
  defaultVariants: {
    variant: "primary",
    size: "md",
  },
});

/**
 * An element that spins around indicating the loading state in the app.
 */

export const LoadingSpinner = React.forwardRef<
  React.ElementRef<typeof Spinner>,
  LoadingSpinnerProps
>(({ size, variant, css }, forwardRef) => {
  const overrides = useThemeOverrides("loadingSpinner");

  return (
    <Spinner
      size={size}
      data-testid={"spinner"}
      role="alert"
      aria-live="assertive"
      id="spinner"
      ref={forwardRef}
      css={{ ...(overrides && overrides), ...css }}
    >
      {Array.from({ length: 4 }, (_value, index: number) => {
        const dataAttrName =
          index === 0
            ? { "data-spinner-background": true }
            : { "data-spinner-animated-bar": true };
        return (
          <InnerLine
            size={size}
            variant={variant}
            key={index}
            {...dataAttrName}
          />
        );
      })}
      <ScreenReaderOnly>Content is loading</ScreenReaderOnly>
    </Spinner>
  );
});
