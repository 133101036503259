import { CSS, styled, theme, useThemeOverrides } from "@truepill/capsule-utils";
import cn from "classnames";
import React from "react";

import { Spacer } from "../spacer/Spacer";
import { Text } from "../text/Text";

/**
 * Radio props
 */
interface RadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
  /** Label to show next to checkbox. If omitted, ensure to add an aria-label. */
  label?: string;
  /** Disable the radio button. */
  disabled?: boolean;
  /** Additional classnames */
  className?: string;
  /** Style overrides for the radio indicator container */
  cssIndicatorContainer?: CSS;
  /** Style overrides for the radio indicator */
  cssIndicator?: CSS;
  /** Style overrides for the radio label */
  cssLabel?: CSS;
}

const StyledInnerCircle = styled("div", {
  position: "relative",
  width: "1.5rem",
  height: "1.5rem",
  transition: "border 0.2s ease-in-out, color 0.2s ease-in-out",
  borderRadius: "$xl",
  border: "1px solid $gray-500",
  boxShadow: "0 1px 0 rgba(22,29,37,0.05)",
  "&::after": {
    transition: "transform 0.1s ease-out",
    content: '""',
    position: "absolute",
    top: "2px",
    left: "2px",
    transform: "scale(0)",
    width: "1rem",
    height: "1rem",
    borderRadius: "$xl",
    background: theme.colors["functional-info-dark"],
  },
});

const StyledInput = styled("input", {
  top: 0,
  left: 0,
  width: "1.5rem",
  height: "1.5rem",
  cursor: "pointer",
  margin: 0,
  opacity: 0,
  padding: 0,
  position: "absolute",

  [`&:focus-visible ~ ${StyledInnerCircle}`]: {
    outline: "-webkit-focus-ring-color auto 1px",
  },
  [`&:checked ~ ${StyledInnerCircle}`]: {
    borderColor: "$functional-info-dark",
    borderWidth: 2,

    "&::after": {
      transform: "scale(1)",
    },
  },
  "&:disabled": {
    cursor: "default",

    [`& ~ ${StyledInnerCircle}`]: {
      opacity: "0.5",
      borderColor: theme.colors["gray-500"],

      "&::after": {
        opacity: "0.5",
        borderColor: theme.colors["gray-500"],
      },
    },
  },
});

const StyledRadio = styled("div", {
  position: "relative",
  display: "inline-block",
});

const Label = styled("label", {
  cursor: "pointer",
  display: "inline-flex",
  alignItems: "center",
  "-webkit-tap-highlight-color": "transparent",

  "&[data-disabled=true]": {
    cursor: "default",
    [`${Text}`]: {
      color: "$gray-500",
    },
  },
});

const StyledText = styled(Text, {
  "&[data-disabled=true]": {
    color: "$gray-500",
  },
});

/**
  Allows the selection of one option from a set.
 */
export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      className,
      label,
      disabled,
      cssIndicator,
      cssIndicatorContainer,
      cssLabel,
      ...rest
    },
    ref
  ) => {
    const radioLabelOverrides = useThemeOverrides("radioLabel");
    const radioIndicatorOverrides = useThemeOverrides("radioIndicator");
    const radioIndicatorContainerOverrides = useThemeOverrides(
      "radioIndicatorContainer"
    );

    return (
      <StyledRadio className={cn("capsule", "radio", className)}>
        <Label data-disabled={disabled}>
          <StyledInput
            css={{
              ...radioIndicatorContainerOverrides,
              ...cssIndicatorContainer,
            }}
            type="radio"
            disabled={disabled}
            ref={ref}
            {...rest}
          />
          <StyledInnerCircle
            className="radio-indicator"
            css={{ ...radioIndicatorOverrides, ...cssIndicator }}
          />
          {label && (
            <>
              <Spacer size="xs" />
              <StyledText
                css={{ ...radioLabelOverrides, ...cssLabel }}
                as="span"
                data-disabled={disabled}
              >
                {label}
              </StyledText>
            </>
          )}
        </Label>
      </StyledRadio>
    );
  }
);
