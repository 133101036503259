import { CSS, styled, useThemeOverrides } from "@truepill/capsule-utils";
import type { ReactElement } from "react";
import React from "react";

type variant = "light" | "mid" | "dark";
type size = "1px" | "2px";

interface DividerProps {
  /** Determine the color variant being shown. Defaults to `dark`. */
  variant?: variant;
  /** Determines the border width. Defaults to `1px`. */
  size?: size;
  /** Determines the border style. Defaults to `solid`. */
  dotted?: boolean;
  /** Allows user to change the text. Defaults to `or`. */
  text?: string;
  /** CSS override for the component */
  css?: CSS;
}

const Text = styled("p", {
  display: "flex",
  position: "relative",
  lineHeight: "20px",
  fontSize: "14px",
  padding: "0 20px",
  textAlign: "center",
  verticalAlign: "middle",
  variants: {
    variant: {
      light: {
        color: "$white",
      },
      mid: {
        color: "$gray-500",
      },
      dark: {
        color: "$gray-700",
      },
    },
    size: {
      "1px": {},
      "2px": {
        fontWeight: 700,
      },
    },
  },
});

const Wrapper = styled("div", {
  display: "flex",
  alignItems: "center",
});

const StyledDivider = styled("div", {
  display: "flex",
  flex: "1 1 auto",
  maxHeight: 1,
  minWidth: 30,
  variants: {
    variant: {
      light: {
        borderColor: "$white",
      },
      mid: {
        borderColor: "$gray-500",
      },
      dark: {
        borderColor: "$gray-700",
      },
    },
    size: {
      "1px": {
        borderWidth: "1px",
      },
      "2px": {
        borderWidth: "2px",
      },
    },
    dotted: {
      true: {
        border: "none",
        borderBottom: "dotted",
      },
      false: {
        borderStyle: "solid",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
      },
    },
  },
});
/**
 * A Divider component.
 */
export const Divider = React.forwardRef<HTMLDivElement, DividerProps>(
  (
    { variant = "dark", size = "1px", dotted = false, text, css, ...rest },
    ref
  ): ReactElement => {
    const dividerOverrides = useThemeOverrides("divider");
    const textOverrides = useThemeOverrides("dividerText");

    const DividerSegment = () => {
      return (
        <StyledDivider
          variant={variant}
          size={size}
          dotted={dotted}
          ref={ref}
          css={{ ...dividerOverrides, ...css }}
          {...rest}
        />
      );
    };

    return (
      <>
        {!text ? (
          <DividerSegment />
        ) : (
          <Wrapper>
            <DividerSegment />
            {text ? (
              <Text variant={variant} size={size} css={{ ...textOverrides }}>
                {text}
              </Text>
            ) : null}
            <DividerSegment />
          </Wrapper>
        )}
      </>
    );
  }
);
