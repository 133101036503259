import { DialogContent, DialogOverlay } from "@reach/dialog";
import { CSS, styled, useThemeOverrides } from "@truepill/capsule-utils";
import cn from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import React, { ReactElement, ReactNode } from "react";
import { X } from "react-feather";

import { Button } from "../button/Button";
import { ScreenReaderOnly } from "../screen-reader-only/ScreenReaderOnly";

interface ModalProps {
  /**
   * Additional classnames
   */
  className?: string;
  /**
   * Determines if the modal is open or not
   */
  isOpen: boolean;
  /**
   * Called when the user presses enter, clicks out of the modal, or clicks the close button
   */
  onDismiss: (event?: React.SyntheticEvent<Element, Event>) => void;
  /**
   * Should an X button be shown
   */
  showCloseButton?: boolean;
  "aria-label"?: string;
  "aria-labelledby"?: string;
  children: ReactNode;
  css?: CSS;
  overlayCss?: CSS;
}

const MotionOverlay = styled(motion(DialogOverlay), {
  "&[data-reach-dialog-overlay]": {
    background: "hsla(219, 18%, 37%, 0.8)",
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    overflow: "auto",
    zIndex: 1,
  },
  "[data-reach-dialog-content]": {
    position: "relative",
    width: "90vw",
    borderRadius: "$md",
    boxShadow: "$md",
    margin: "10vh auto",
    background: "$white",
    padding: "2rem",
    outline: "none",
    maxWidth: "33rem",
    "@desktop": {
      maxWidth: "36rem",
    },
  },
});

const CloseButton = styled(Button, {
  position: "absolute",
  display: "inline-flex",
  alignItems: "center",
  border: "none",
  right: "0.5rem",
  top: "0.5rem",
  padding: 0,
  "*:not(:first-child)": {
    marginLeft: 0,
  },
});

const MotionDialogContent = motion(DialogContent);

/**
  A window overlaid on either the primary window or another dialog window, rendering the content underneath inert.
 */
export const Modal = ({
  className,
  onDismiss,
  isOpen,
  showCloseButton = true,
  children,
  css,
  overlayCss,
  ...rest
}: ModalProps): ReactElement => {
  const overrides = useThemeOverrides("modal");
  const overlayOverrides = useThemeOverrides("modalOverlay");
  return (
    <AnimatePresence>
      {isOpen && (
        <MotionOverlay
          className={cn("capsule", "modal", className)}
          onDismiss={onDismiss}
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          transition={{
            type: "tween",
            duration: 0.2,
          }}
          css={{
            "[data-reach-dialog-content]": {
              ...overrides,
              ...css,
            },
            "&[data-reach-dialog-overlay]": {
              ...overlayOverrides,
              ...overlayCss,
            },
          }}
        >
          <MotionDialogContent
            aria-label={rest["aria-label"]}
            aria-labelledby={rest["aria-labelledby"]}
            animate={{ y: 0, opacity: 1 }}
            initial={{ y: -80, opacity: 0 }}
            exit={{
              y: -80,
              opacity: 0,
              transition: {
                type: "tween",
                duration: 0.3,
              },
            }}
            transition={{
              type: "spring",
              duration: 0.4,
            }}
          >
            {showCloseButton && (
              <CloseButton variant="primary-text" size="xs" onClick={onDismiss}>
                <ScreenReaderOnly>Close modal</ScreenReaderOnly>
                <X />
              </CloseButton>
            )}
            {children}
          </MotionDialogContent>
        </MotionOverlay>
      )}
    </AnimatePresence>
  );
};
