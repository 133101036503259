import { Switch } from "@headlessui/react";
import { CSS, styled, useThemeOverrides } from "@truepill/capsule-utils";
import * as React from "react";
import { Check, X } from "react-feather";

import { ScreenReaderOnly } from "../screen-reader-only/ScreenReaderOnly";
import { Spacer } from "../spacer/Spacer";
import { Text } from "../text/Text";

export interface ToggleProps {
  /** If true, adds on and off text to the toggle.*/
  withText?: boolean;
  /**If true, adds icons to the toggle.*/
  withIcons?: boolean;
  /** Disables the toggle, and adds disabled styling.*/
  disabled?: boolean;
  /** If passed, will display a label next to the toggle. Clicking the label will update the toggle. */
  label?: string;
  /** Function to call if toggle is switched. */
  onChange?: (checked: boolean) => void;
  /** Is the toggle checked. */
  checked?: boolean;
  /** CSS override for the switch. */
  switchCss?: CSS;
  /** CSS override for the label. */
  labelCss?: CSS;
}

const SwitchWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
});

const StyledSwitch = styled(Switch, {
  position: "relative",
  display: "inline-flex",
  flexShrink: 0,
  cursor: "pointer",
  transition: "background-color 200ms ease-in-out",
  alignItems: "center",
  width: "57px",
  padding: "0.125rem",
  borderRadius: "$md",
  boxShadow: "inset 0px 1px 1px rgba(0, 0, 0, 0.25)",
  border: "none",
  backgroundColor: "$gray-300",
  "&:disabled": {
    opacity: 0.5,
    cursor: "default",
  },
  '&[data-enabled="true"]': {
    backgroundColor: "$functional-info-dark",
  },
});

const Circle = styled("span", {
  pointerEvents: "none",
  position: "relative",
  zIndex: 1,
  display: "inline-block",
  height: "1.5rem",
  width: "1.5rem",
  borderRadius: "$md",
  backgroundColor: "$gray-700",
  transition: "all 200ms ease-in-out",
  boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
  '&[data-enabled="true"]': {
    transform: "translateX(calc(57px - 1.5rem - 4px))",
    backgroundColor: "$white",
  },
});

const IconWrapper = styled("span", {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transitionProperty: "opacity",
  transitionDuration: "100ms",
  opacity: 0,
  color: "$functional-info-light",
  '&[data-visible="true"]': {
    transitionDuration: "200ms",
    opacity: 1,
  },
});

const CheckIconWrapper = styled(IconWrapper, {
  color: "$functional-info-dark",
});

const OnText = styled(Text, {
  position: "absolute",
  width: "28px",
  color: "$typography-white",
  transitionProperty: "opacity",
  transitionDuration: "100ms",
  opacity: 0,
  fontSize: "calc(0.875 * $md)",
  lineHeight: "$md",
  '&[data-visible="true"]': {
    transitionDuration: "200ms",
    opacity: 1,
  },
});

const OffText = styled(Text, {
  position: "absolute",
  right: "8px",
  color: "$typography-dark",
  transitionProperty: "opacity",
  transitionDuration: "100ms",
  opacity: 0,
  fontSize: "calc(0.875 * $md)",
  lineHeight: "$md",
  '&[data-visible="true"]': {
    transitionDuration: "200ms",
    opacity: 1,
  },
});

const Label = styled(Text, {
  cursor: "pointer",
});

/**
 * A Toggle component.
 */
export const Toggle = React.forwardRef<HTMLButtonElement, ToggleProps>(
  (
    {
      withIcons = false,
      withText = false,
      disabled = false,
      label,
      checked,
      onChange,
      switchCss,
      labelCss,
    },
    ref
  ): React.ReactElement => {
    const switchOverrides = useThemeOverrides("toggleSwitch");
    const labelOverrides = useThemeOverrides("toggleLabel");

    return (
      <Switch.Group>
        <SwitchWrapper>
          {label && (
            <>
              <Switch.Label
                as={Label}
                variant="body-sm"
                css={{ ...labelOverrides, ...labelCss }}
              >
                {label}
              </Switch.Label>
              <Spacer />
            </>
          )}
          <StyledSwitch
            checked={checked}
            onChange={onChange}
            data-enabled={checked}
            disabled={disabled}
            ref={ref}
            css={{ ...switchOverrides, ...switchCss }}
          >
            {withText && (
              <OnText
                variant="body-sm"
                as="span"
                data-visible={checked}
                aria-hidden="true"
                data-toggle-on-text
              >
                on
              </OnText>
            )}
            <ScreenReaderOnly>Use setting</ScreenReaderOnly>
            <Circle data-enabled={checked} data-toggle-indicator>
              {withIcons && (
                <div data-testid="cap-toggle-icons">
                  <IconWrapper
                    data-toggle-off-icon
                    data-visible={!checked}
                    aria-hidden="true"
                  >
                    <X size={16} strokeWidth={2.5} color="currentColor" />
                  </IconWrapper>
                  <CheckIconWrapper
                    data-toggle-on-icon
                    data-visible={checked}
                    aria-hidden="true"
                  >
                    <Check size={16} strokeWidth={2.5} color="currentColor" />
                  </CheckIconWrapper>
                </div>
              )}
            </Circle>
            {withText && (
              <OffText
                variant="body-sm"
                as="span"
                data-visible={!checked}
                aria-hidden="true"
                data-toggle-off-text
              >
                off
              </OffText>
            )}
          </StyledSwitch>
        </SwitchWrapper>
      </Switch.Group>
    );
  }
);
