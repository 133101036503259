import {
  CSS,
  css as stitchesCss,
  useThemeOverrides,
} from "@truepill/capsule-utils";
import cn from "classnames";
import React, { HTMLAttributes } from "react";
import type {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
  PolymorphicPropsWithRef,
} from "react-polymorphic-types";

import type { HeaderVariant } from "../../types";

const HeaderBaseElement = "h6";
interface BaseHeaderProps extends HTMLAttributes<HTMLHeadingElement> {
  /** Determines the variant of the header*/
  variant?: HeaderVariant;
  /** Use to override the semantic component being rendered.*/
  as?: React.ElementType;
  /** Make the header bold*/
  bold?: boolean | "true" | "false";
  /** Underline the header*/
  underline?: boolean;
  /** additional classnames */
  className?: string;
  /* CSS override for the component */
  css?: CSS;
}

export type HeaderProps = PolymorphicPropsWithRef<
  BaseHeaderProps,
  typeof HeaderBaseElement
>;

type headers = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

const variantsMap: Record<HeaderVariant, headers> = {
  xl: "h6",
  "2xl": "h5",
  "3xl": "h4",
  "4xl": "h3",
  "5xl": "h2",
  "6xl": "h1",
  "7xl": "h1",
  "8xl": "h1",
};

const baseHeaderStyles = stitchesCss({
  fontFamily: "$header",
  margin: 0,
  "&[data-bold=true]": { fontWeight: "700" },
  "&[data-underline=true]": { textDecoration: "underline" },
  variants: {
    variant: {
      xl: {
        fontSize: "1.125rem",
        lineHeight: "$lg",
        "@desktop": {
          fontSize: "1.25rem",
          lineHeight: "1.675rem",
          letterSpacing: "-0.016rem",
        },
      },
      "2xl": {
        fontSize: "1.25rem",
        lineHeight: "$lg",
        letterSpacing: "-0.016rem",
        "@tablet": {
          fontSize: "1.375rem",
          lineHeight: "1.75rem",
        },
        "@desktop": {
          fontSize: "$lg",
          lineHeight: "1.875",
        },
      },
      "3xl": {
        fontSize: "$lg",
        lineHeight: "1.75rem",
        letterSpacing: "-0.031rem",
        fontWeight: "700",
        "@tablet": {
          fontSize: "1.625rem",
          lineHeight: "1.875rem",
        },
        "@desktop": {
          fontSize: "1.875rem",
          lineHeight: "2.125rem",
        },
      },
      "4xl": {
        fontSize: "1.875rem",
        lineHeight: "$xl",
        letterSpacing: "-0.031rem",
        fontWeight: "700",
        "@tablet": {
          fontSize: "$xl",
          lineHeight: "2.25rem",
        },
        "@desktop": {
          fontSize: "2.25rem",
          lineHeight: "2.5rem",
          letterSpacing: "-0.034rem",
        },
      },
      "5xl": {
        fontSize: "2.25rem",
        lineHeight: "2.313rem",
        letterSpacing: "-0.034rem",
        fontWeight: "700",
        "@tablet": {
          fontSize: "2.625rem",
          lineHeight: "2.625rem",
          letterSpacing: "-0.047rem",
        },
        "@desktop": {
          fontSize: "3rem",
          lineHeight: "3.25rem",
          letterSpacing: "-0.063rem",
        },
      },
      "6xl": {
        fontSize: "2.625rem",
        lineHeight: "2.625rem",
        letterSpacing: "-0.047rem",
        fontWeight: "700",
        "@tablet": {
          fontSize: "3.125rem",
          lineHeight: "3.125rem",
          letterSpacing: "-0.063rem",
        },
        "@desktop": {
          fontSize: "3.75rem",
          lineHeight: "3.75rem",
          letterSpacing: "-0.078rem",
        },
      },
      "7xl": {
        fontSize: "3rem",
        lineHeight: "2.875rem",
        letterSpacing: "-0.063rem",
        fontWeight: "700",
        "@tablet": {
          fontSize: "3.5rem",
          lineHeight: "3.375rem",
          letterSpacing: "-0.047rem",
        },
        "@desktop": {
          fontSize: "4.5rem",
          lineHeight: "4.313rem",
          letterSpacing: "-0.094rem",
        },
      },
      "8xl": {
        fontSize: "3.5rem",
        lineHeight: "3.25rem",
        letterSpacing: "-0.063rem",
        fontWeight: "700",
        "@tablet": {
          lineHeight: "3.875rem",
          letterSpacing: "-0.047rem",
        },
        "@desktop": {
          fontSize: "6rem",
          lineHeight: "6rem",
          letterSpacing: "-0.125rem",
        },
      },
    },
  },
});

/**
 * A header element that encompasses Truepill typography system.
 */

export const Header: PolymorphicForwardRefExoticComponent<
  BaseHeaderProps,
  typeof HeaderBaseElement
> = React.forwardRef(
  <T extends React.ElementType = typeof HeaderBaseElement>(
    {
      variant = "xl",
      as,
      bold,
      underline,
      css,
      className,
      ...rest
    }: PolymorphicPropsWithoutRef<BaseHeaderProps, T>,
    ref: React.ForwardedRef<Element>
  ) => {
    const styleProps = {} as CSS;
    if (bold) {
      styleProps.fontWeight = "bold";
    }
    if (underline) {
      styleProps.textDecoration = "underline";
    }

    const overrides = useThemeOverrides("header");
    const Element: React.ElementType = as || variantsMap[variant];

    return (
      <Element
        {...rest}
        ref={ref}
        variant={variant}
        className={cn(
          className,
          baseHeaderStyles({
            variant,
            css: {
              ...styleProps,
              ...overrides,
              ...css,
            },
          })
        )}
      />
    );
  }
);
