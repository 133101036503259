import { CSS, styled, useThemeOverrides } from "@truepill/capsule-utils";
import cn from "classnames";
import React, { ReactElement } from "react";
import { HTMLAttributes } from "react";

import type { Spacing } from "../../types";

type Shadows = "none" | "sm" | "md" | "lg" | "xl" | "2xl";
export type CardColor = "white" | "primary" | "secondary" | "gray";
type allContent = {
  [x: string]: {
    [y: string]: string | number;
  };
};
/**
 * Card props
 */
interface CardProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * Define the box shadow shown around Card.
   */
  shadow?: Shadows;
  /**
   * The surface color of Card.
   */
  color?: CardColor;
  /**
   * The amount of padding the card has. Based on the spacing system.
   */
  padding?: Spacing;
  /**
   * Determine if the card will be as wide as its content, or as wide as its parent..
   */
  width?: "auto" | "fit";
  css?: CSS;
}

const shadows = ["none", "sm", "md", "lg", "xl", "2xl"] as Shadows[];
const spaces = [
  "none",
  "2xs",
  "xs",
  "sm",
  "md",
  "lg",
  "xl",
  "2xl",
  "3xl",
  "4xl",
  "5xl",
  "6xl",
  "7xl",
  "8xl",
  "9xl",
] as Spacing[];

const getStylesByVariant = (variant: string, variants: string[]) => {
  return variants.reduce((allContent: allContent, content: string) => {
    allContent[content] = {
      [variant]: `$${content}`,
    };

    return allContent;
  }, {});
};

const StyledCard = styled("div", {
  variants: {
    width: {
      fit: {
        width: "fit-content",
      },
      auto: {
        width: "auto",
      },
    },
    shadow: getStylesByVariant("box-shadow", shadows),
    padding: getStylesByVariant("padding", spaces),
    color: {
      white: {
        backgroundColor: "$white",
        color: "$typography-dark",
      },
      primary: {
        backgroundColor: "$primary-700",
        color: "$typography-light",
      },
      secondary: {
        backgroundColor: "$secondary-100",
        color: "$typography-dark",
      },
      gray: {
        backgroundColor: "$gray-100",
        color: "$typography-dark",
      },
    },
  },
});

/**
  Used to group information about a subject and related actions.
 */
export const Card = ({
  className,
  color = "white",
  shadow = "md",
  padding = "md",
  width = "fit",
  css,
  ...rest
}: CardProps): ReactElement => {
  const themeOverride = useThemeOverrides("card");
  return (
    <StyledCard
      className={cn("capsule", "card", className)}
      color={color}
      padding={padding}
      shadow={shadow}
      width={width}
      css={{ ...css, ...themeOverride }}
      {...rest}
    />
  );
};
