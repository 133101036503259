import {
  CSS,
  css as stitchesCss,
  useThemeOverrides,
} from "@truepill/capsule-utils";
import cn from "classnames";
import React from "react";
import type {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
  PolymorphicPropsWithRef,
} from "react-polymorphic-types";

import type { TextVariant } from "../../types";

const TextBaseElement = "p";

interface BaseTextProps {
  /** Determines the variant of the text*/
  variant?: TextVariant;
  /** Make the text bold*/
  bold?: boolean;
  /** Underline the text*/
  underline?: boolean;
  css?: CSS;
}

export type TextProps = PolymorphicPropsWithRef<
  BaseTextProps,
  typeof TextBaseElement
>;

type headers = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

const variantsMap: Record<TextVariant, "p" | "span" | headers> = {
  chip: "span",
  overline: "span",
  caption: "span",
  body: "p",
  "body-sm": "p",
};

const baseTextStyles = stitchesCss({
  fontFamily: "$base",
  "&[data-bold=true]": { fontWeight: "700" },
  "&[data-underline=true]": { textDecoration: "underline" },
  variants: {
    variant: {
      chip: {
        fontSize: "0.6875em",
        lineHeight: "$md",
        textTransform: "uppercase",
        fontWeight: "bold",
      },
      overline: {
        fontSize: "$md",
        lineHeight: "1.375",
        textTransform: "uppercase",
        letterSpacing: "0.063rem",
      },
      caption: {
        fontSize: "$sm",
        lineHeight: "$md",
      },
      "body-sm": {
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
      },
      body: {
        fontSize: "$md",
        lineHeight: "1.375",
      },
    },
  },
});

/**
 * A text element that encompasses Truepill typography system.
 */

export const Text: PolymorphicForwardRefExoticComponent<
  BaseTextProps,
  typeof TextBaseElement
> = React.forwardRef(
  <T extends React.ElementType = typeof TextBaseElement>(
    {
      variant,
      as,
      bold,
      underline,
      css,
      className,
      ...rest
    }: PolymorphicPropsWithoutRef<BaseTextProps, T>,
    ref: React.ForwardedRef<Element>
  ) => {
    const styleProps = {} as CSS;
    if (bold) {
      styleProps.fontWeight = "bold";
    }
    if (underline) {
      styleProps.textDecoration = "underline";
    }

    const overrides = useThemeOverrides("text");
    const Element: React.ElementType = as || variantsMap[variant || "body"];

    return (
      <Element
        {...rest}
        ref={ref}
        className={cn(
          className,
          baseTextStyles({
            variant,
            css: {
              ...styleProps,
              ...overrides,
              ...css,
            },
          })
        )}
      />
    );
  }
);
