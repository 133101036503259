import { CSS, styled, useThemeOverrides } from "@truepill/capsule-utils";
import cn from "classnames";
import React, { ReactElement } from "react";

import type { Spacing } from "../../types";

type Items = "start" | "center" | "end" | "stretch";
type Content =
  | "start"
  | "end"
  | "center"
  | "stretch"
  | "space-around"
  | "space-between"
  | "space-evenly";
type mobileColSpan = 1 | 2 | 3 | 4;
type tabletColSpan = mobileColSpan | 5 | 6 | 7 | 8;
type desktopColSpan = tabletColSpan | 9 | 10 | 11 | 12;
type allContent = {
  [x: string]: {
    [y: string]: string | number;
  };
};

interface GridProps extends React.HTMLAttributes<HTMLDivElement> {
  /** Define the space between grid items */
  spacing?: Spacing;
  /** CSS Grid justify-items */
  justifyItems?: Items;
  /** CSS Grid justify-content */
  justifyContent?: Content;
  /** CSS Grid align-items */
  alignItems?: Items;
  /** CSS Grid align-content */
  alignContent?: Content;
  css?: CSS;
}

interface GridItemProps extends React.HTMLAttributes<HTMLDivElement> {
  /** Define the space to take up from mobile and above. Columns span out 4 on mobile */
  mobile?: mobileColSpan;
  /** Define the space to take up from tablet and above. Columns span out 8 on tablet */
  tablet?: tabletColSpan;
  /** Define the space to take up from desktop and above. Columns span out 12 on desktop */
  desktop?: desktopColSpan;
  css?: CSS;
}

const spaces = [
  "none",
  "2xs",
  "xs",
  "sm",
  "md",
  "lg",
  "xl",
  "2xl",
  "3xl",
  "4xl",
  "5xl",
  "6xl",
  "7xl",
  "8xl",
  "9xl",
] as Spacing[];

const contentVariants = [
  "start",
  "end",
  "center",
  "stretch",
  "space-around",
  "space-between",
  "space-evenly",
] as Content[];

const itemVariants = ["start", "center", "end", "stretch"] as Items[];

const spacesWithStyles = spaces.reduce(
  (allSpaces: allContent, space: string | number) => {
    allSpaces[space] = {
      gridGap: `$${space}`,
    };

    return allSpaces;
  },
  {}
);

const getStylesByVariant = (variant: string, variants: string[]) => {
  return variants.reduce((allContent: allContent, content: string) => {
    allContent[content] = {
      [variant]: `${content}`,
    };

    return allContent;
  }, {});
};

const StyledGrid = styled("div", {
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  "@tablet": {
    gridTemplateColumns: "repeat(8, 1fr)",
  },
  "@desktop": {
    gridTemplateColumns: "repeat(12, 1fr)",
  },
  variants: {
    spacing: spacesWithStyles,
    justifyItems: getStylesByVariant("justifyItems", itemVariants),
    justifyContent: getStylesByVariant("justifyContent", contentVariants),
    alignItems: getStylesByVariant("alignItems", itemVariants),
    alignContent: getStylesByVariant("alignContent", contentVariants),
  },
});

const mobileColSpan = [1, 2, 3, 4];
const tabletColSpan = [...mobileColSpan, 5, 6, 7, 8];
const desktopColSpan = [...tabletColSpan, 9, 10, 11, 12];

const StyledGridItem = styled("div", {
  margin: "0",
});

/**
    Column Grid with responsive capabilities.

    @param spacing - Define the space between grid items. Uses the Capsule spacing values.
    @param justifyItems - Defines the default justify-self for all items of the box, giving them the default way of justifying each box along the appropriate axis.
    @param justifyContent - Sometimes the total size of your grid might be less than the size of its grid container. This could happen if all of your grid items are sized with non-flexible units like px. In this case you can set the alignment of the grid within the grid container. This property aligns the grid along the inline (row) axis (as opposed to align-content which aligns the grid along the block (column) axis).
    @param alignItems - Aligns grid items along the block (column) axis (as opposed to justify-items which aligns along the inline (row) axis). This value applies to all grid items inside the container.
    @param alignContent - Sometimes the total size of your grid might be less than the size of its grid container. This could happen if all of your grid items are sized with non-flexible units like px. In this case you can set the alignment of the grid within the grid container. This property aligns the grid along the block (column) axis (as opposed to justify-content which aligns the grid along the inline (row) axis).

 */

export const getColSpan = (
  breakpoints: (number | undefined)[]
): number | undefined => {
  let span;
  for (const breakpoint of breakpoints) {
    if (breakpoint) {
      span = breakpoint;
    }
  }
  return span;
};

export const Grid = ({
  className,
  spacing = "md",
  css,
  ...rest
}: GridProps): ReactElement => {
  const gridThemeOverride = useThemeOverrides("grid");

  return (
    <StyledGrid
      className={cn("capsule", "grid", className)}
      spacing={spacing}
      css={{ ...css, ...gridThemeOverride }}
      {...rest}
    />
  );
};

/**
    Individual grid item to use as a child of Grid.

    @param mobile - Capsule uses a 4 column grid on mobile. Define the amount of columns for the grid item to span.
    @param tablet - Capsule uses a 8 column grid on tablet. Define the amount of columns for the grid item to span.
    @param desktop - Capsule uses a 12 column grid on desktop. Define the amount of columns for the grid item to span.
 */
export const GridItem = ({
  className,
  css,
  mobile,
  tablet,
  desktop,
  ...rest
}: GridItemProps): ReactElement => {
  const gridItemThemeOverride = useThemeOverrides("gridItem");
  const tabletBreakpoint = getColSpan([mobile ? mobile * 2 : mobile, tablet]);
  const desktopBreakpoint = getColSpan([
    mobile ? mobile * 3 : mobile,
    tablet ? tablet * 1.5 : tablet,
    desktop,
  ]);
  return (
    <StyledGridItem
      className={cn("capsule", "grid-item", className)}
      css={{
        gridColumn: `span ${mobile}`,
        "@tablet": {
          gridColumn: `span ${tabletBreakpoint}`,
        },
        "@desktop": {
          gridColumn: `span ${desktopBreakpoint}`,
        },
        ...css,
        ...gridItemThemeOverride,
      }}
      {...rest}
    />
  );
};
