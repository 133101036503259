import { useId } from "@reach/auto-id";
import { Portal } from "@reach/portal";
import { CSS, styled, useThemeOverrides } from "@truepill/capsule-utils";
import Downshift from "downshift";
import { AnimatePresence, motion } from "framer-motion";
import React, { ReactElement, useRef } from "react";
import { ChevronDown, Search, X as Clear } from "react-feather";

import { ScreenReaderOnly } from "../screen-reader-only/ScreenReaderOnly";
import { Text } from "../text/Text";

type AutocompleteState = "default" | "error";
type Variant = "small" | "large";
type OptionComponent<T> = ({
  option,
  isHighlighted,
}: {
  option: T;
  isHighlighted: boolean;
}) => ReactElement;

export interface AutocompleteProps<T> {
  /**
   * Enable the button to clear the input value.
   */
  clearable?: boolean;
  /**
   * Disable the component.
   */
  disabled?: boolean;
  /**
   * Text to show under the input. Will be styled according to the `state` prop
   */
  helperText?: string;
  /**
   * ID of the element.
   */
  id?: string;
  /**
   * Configure the label text to be shown above the input.
   */
  label?: string;
  /**
   * Message displayed when no options match the input value
   */
  noResultsText?: string;
  /**
   * Used along with value to control Autocomplete. Gives the selected option as an argument rather than an event object.
   */
  onChange: (selectedOption: T | null) => void;
  /**
   * Fires when the input value changes. It returns the current value of the input.
   */
  onInputValueChange?: (inputValue: string) => void;
  /**
   * Allow the customization of the component being rendered as an option. Gives access to the options, as well as an `isHighlighted` boolean, which determines if the element is currently highlighted by the user.
   */
  optionComponent?: OptionComponent<T>;
  /**
   * Options available for the user to select. This can be a string or an object. If given as an object, the selectedKey prop must be provided.
   */
  options: T[];
  /**
   * Text to show if no option has been selected.
   */
  placeholder?: string;
  /**
   * Updates styling of the input to show it is required.
   */
  required?: boolean;
  /**
   * If the items passed to the `options` prop are objects, this determines the item in the object to display when the user selects an item.
   */
  selectedKey?: keyof T;
  /**
   * Determine the styling of the input. Useful for validation.
   */
  state?: AutocompleteState;
  /**
   * Input html type attribute.
   */
  type?: string;
  /**
   * Use this to control the value shown in the input.
   */
  value: T;
  /**
   * Updates styling of the input to show it is required.
   */
  variant?: Variant;
  /**
   * Whether the Autocomplete options list should render in a `Portal` (default: `false`)
   */
  portalled?: boolean;
  css?: CSS;
  triggerCss?: CSS;
  textFieldCss?: CSS;
}

interface IAutoComplete {
  <T>(props: AutocompleteProps<T>): ReactElement | null;
}

const AutocompleteWrapper = styled("div", {
  width: "100%",
});

const AutocompleteInputWrapper = styled("div", {
  position: "relative",
  variants: {
    state: {
      default: {
        "$$autocomplete-field-outline": "1px solid $colors$gray-700",
        "$$autocomplete-field-outline-focus":
          "inset 0px 0px 0px 2px $colors$primary-500",
        "$$autocomplete-field-background": "$colors$white",
      },
      complete: {
        "$$autocomplete-field-outline": "1px solid $colors$gray-500",
        "$$autocomplete-field-outline-focus":
          "inset 0px 0px 0px 2px $colors$primary-500",
        "$$autocomplete-field-background": "$colors$gray-100",
      },
      error: {
        "$$autocomplete-field-outline":
          "1px solid $colors$functional-error-dark",
        "$$autocomplete-field-outline-focus":
          "inset 0px 0px 0px 2px $colors$functional-error-dark",
        "$$autocomplete-field-background": "$colors$functional-error-light",
      },
    },
  },
});

const Menu = styled(motion.ul, {
  position: "absolute",
  width: "100%",
  zIndex: 1000,
  borderRadius: "$sm",
  padding: "0.5rem 0",
  maxHeight: "18.5rem",
  overflow: "hidden",
  overflowY: "auto",
  outline: "none",
  boxShadow:
    "0px 2px 4px rgba(0, 0, 0, 0.1), 0px 6px 8px rgba(50, 50, 93, 0.22)",
  background: "$white",
  variants: {
    variant: {
      small: {
        top: "48px",
      },
      large: {
        top: "56px",
      },
    },
  },
});

const Item = styled("li", {
  listStyle: "none",
  cursor: "pointer",
});

const ItemContent = styled(Text, {
  padding: "0.5rem 1rem",
  position: "relative",
});

const ItemBackground = styled("span", {
  display: "block",
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: -1,
  backgroundColor: "$primary-300",
});

const Button = styled("button", {
  textAlign: "center",
  padding: "0 1rem",
  position: "absolute",
  right: 0,
  top: 0,
  lineHeight: "1em",
  border: "none",
  background: "transparent",

  svg: {
    position: "relative",
    top: "1px",
  },
  variants: {
    variant: {
      small: {
        height: "48px",
      },
      large: {
        height: "56px",
      },
    },
  },
});

const SearchIconWrapper = styled("span", {
  display: "flex",
  position: "absolute",
  left: 0,
  top: 0,
  minWidth: "56px",
  alignItems: "center",
  justifyContent: "center",

  variants: {
    variant: {
      small: {
        height: "48px",
      },
      large: {
        height: "56px",
      },
    },
    disabled: {
      true: { opacity: 0.4 },
      false: {},
    },
  },
});

const ClearButton = styled(Button, {
  outline: "none",
  "&:focus": {
    boxShadow: "$$autocomplete-field-outline-focus",
  },
  right: "50px",
});

const InputContainer = styled("div", {
  display: "flex",
});

const StyledHelperText = styled(Text, {
  display: "block",
  marginTop: "0.5rem",
  variants: {
    state: {
      default: {},
      complete: {},
      error: {
        color: "$functional-error-dark",
      },
    },
  },
});
const StyledTextField = styled("input", {
  border: "$$autocomplete-field-outline",
  background: "$$autocomplete-field-background",
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: "0.75rem",
  borderRadius: "$sm",
  width: "100%",
  outline: "none",
  "&[type='search']": {
    paddingLeft: "56px",
  },
  "&:disabled": {
    opacity: 0.4,
  },
  "&:focus": {
    backgroundColor: "$$autocomplete-field-background",
    boxShadow: "$$autocomplete-field-outline-focus",
  },
  "&[type='search']::-webkit-search-decoration, &[type='search']::-webkit-search-cancel-button, &[type='search']::-webkit-search-results-button,&[type='search']::-webkit-search-results-decoration":
    {
      webkitAppearance: "none",
    },
  variants: {
    variant: {
      small: {
        height: "48px",
      },
      large: {
        height: "56px",
      },
    },
    withClearBtn: {
      true: {
        paddingRight: "calc(0.75rem + 112px)",
      },
      false: {
        paddingRight: "calc(0.75rem + 56px)",
      },
    },
  },
});

const PortalledBackground = styled("div", {
  size: "100%",
  position: "fixed",
  top: 0,
});
const PortalledContainer = styled("div", {
  position: "absolute",
  width: "100%",
  maxWidth: "250px",
});

const MotionChevron = motion(ChevronDown);
const MotionClear = motion(Clear);
const ForwardRefLabel = React.forwardRef<
  React.ElementRef<typeof Text>,
  React.ComponentProps<typeof Text>
>((props, forwardedRef) => {
  return (
    <Text
      as="label"
      variant="body"
      bold
      {...props}
      css={{
        display: "flex",
        alignItems: "center",
        marginBottom: "$sm",
        ".required": {
          color: "$functional-error-dark",
          marginLeft: "0.125rem",
        },
        ".field-title": {
          whiteSpace: "nowrap",
        },
      }}
      ref={forwardedRef}
    />
  );
});

/**
 * Custom aria compliant autocomplete component. Also handles the showing of a label, and any validation messages.
 */

export const Autocomplete: IAutoComplete = React.forwardRef(
  (
    {
      clearable = true,
      disabled = false,
      helperText,
      id,
      label,
      optionComponent,
      options,
      onChange,
      onInputValueChange,
      placeholder = "",
      required,
      selectedKey,
      state = "default",
      type,
      value,
      variant = "large",
      noResultsText = "No items match your query",
      portalled = false,
      css,
      triggerCss,
      textFieldCss,
    },
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const inputContainerRef = useRef<HTMLDivElement>();
    const autocompleteId = useId(id);
    const overrides = useThemeOverrides("autocomplete");
    const triggerOverrides = useThemeOverrides("autocompleteTrigger");
    const textFieldOverrides = useThemeOverrides("autocompleteTextField");

    function isString(val: unknown): val is string {
      return typeof val === "string";
    }
    function isObject(val: unknown): val is typeof options[0] {
      return typeof val === "object" && val !== null;
    }
    function getFilteredOptions(inputValue: string | null) {
      return options.filter((item) => {
        const itemValue = determineText(item) as string;
        return (
          !inputValue ||
          itemValue.toLowerCase().includes(inputValue.toLowerCase())
        );
      });
    }
    const determineText = (item: unknown) => {
      if (!options.length) return "";
      if (isString(item) && item.length > 0) return item;
      if (isObject(item) && selectedKey) return item[selectedKey];
      return "";
    };

    return (
      <Downshift
        onChange={(selectedOption) => onChange && onChange(selectedOption)}
        onInputValueChange={(inputValue) =>
          onInputValueChange && onInputValueChange(inputValue)
        }
        selectedItem={value}
        itemToString={(item) => determineText(item) as string}
      >
        {({
          getInputProps,
          getMenuProps,
          getItemProps,
          getToggleButtonProps,
          getLabelProps,
          getRootProps,
          isOpen,
          highlightedIndex,
          inputValue,
          clearSelection,
        }) => {
          const OptionsList = (
            <Menu
              {...getMenuProps({}, { suppressRefError: true })}
              animate={{ y: 0, opacity: 1 }}
              initial={{ y: -50, opacity: 0 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ type: "spring", duration: 0.2 }}
              static
            >
              {getFilteredOptions(inputValue).length > 0 ? (
                getFilteredOptions(inputValue).map((item, index) => {
                  const itemValue = determineText(item) as string;
                  const OptionComponent = () =>
                    optionComponent ? (
                      optionComponent({
                        option: item,
                        isHighlighted: highlightedIndex === index,
                      })
                    ) : (
                      <ItemContent variant="body-sm">
                        {highlightedIndex === index && <ItemBackground />}
                        {itemValue}
                      </ItemContent>
                    );
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <Item
                      {...getItemProps({
                        item,
                        index,
                        key: itemValue,
                      })}
                    >
                      <OptionComponent />
                    </Item>
                  );
                })
              ) : (
                <Item>
                  <ItemContent variant="body-sm">{noResultsText}</ItemContent>
                </Item>
              )}
            </Menu>
          );

          return (
            <AutocompleteWrapper
              {...getRootProps()}
              css={{ ...overrides, ...css }}
            >
              {label && (
                <ForwardRefLabel
                  {...getLabelProps()}
                  as="label"
                  htmlFor={autocompleteId}
                  bold
                >
                  <span className="field-title">{label}</span>
                  {required && (
                    <>
                      <span className="required" aria-hidden>
                        &#42;
                      </span>
                      <ScreenReaderOnly>Required</ScreenReaderOnly>
                    </>
                  )}
                </ForwardRefLabel>
              )}
              <AutocompleteInputWrapper state={state}>
                {/* @ts-expect-error - ref type from motion is incorrect */}
                <InputContainer ref={inputContainerRef}>
                  <StyledTextField
                    {...getInputProps()}
                    variant={variant}
                    required={required}
                    placeholder={placeholder}
                    id={autocompleteId}
                    ref={ref}
                    disabled={disabled}
                    withClearBtn={clearable && !!inputValue}
                    type={type}
                    css={{
                      ...textFieldOverrides,
                      ...textFieldCss,
                    }}
                  />
                  {type === "search" && (
                    <SearchIconWrapper variant={variant} disabled={disabled}>
                      <Search />
                    </SearchIconWrapper>
                  )}
                  <AnimatePresence>
                    {clearable && inputValue && (
                      <ClearButton
                        variant={variant}
                        disabled={disabled}
                        onClick={() => clearSelection()}
                      >
                        <ScreenReaderOnly>Clear</ScreenReaderOnly>
                        <MotionClear
                          animate={{ opacity: 1 }}
                          initial={{ opacity: 0 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.3 }}
                        />
                      </ClearButton>
                    )}
                  </AnimatePresence>
                  <Button
                    variant={variant}
                    disabled={disabled}
                    {...getToggleButtonProps()}
                    css={{
                      ...triggerOverrides,
                      ...triggerCss,
                    }}
                  >
                    <MotionChevron
                      animate={isOpen ? { rotate: 180 } : { rotate: 0 }}
                      transition={{ duration: 0.2 }}
                    />
                  </Button>
                </InputContainer>
                <AnimatePresence>
                  {isOpen &&
                    (portalled ? (
                      <Portal>
                        <>
                          <PortalledBackground />
                          <PortalledContainer
                            css={{
                              left:
                                inputContainerRef.current &&
                                (
                                  inputContainerRef.current as HTMLDivElement
                                ).getBoundingClientRect().left,
                              top:
                                inputContainerRef.current &&
                                (
                                  inputContainerRef.current as HTMLDivElement
                                ).getBoundingClientRect().bottom +
                                  window.scrollY,
                            }}
                          >
                            {OptionsList}
                          </PortalledContainer>
                        </>
                      </Portal>
                    ) : (
                      OptionsList
                    ))}
                </AnimatePresence>
                {helperText && (
                  <>
                    <StyledHelperText
                      variant="body-sm"
                      id={autocompleteId}
                      state={state}
                    >
                      {helperText}
                    </StyledHelperText>
                  </>
                )}
              </AutocompleteInputWrapper>
            </AutocompleteWrapper>
          );
        }}
      </Downshift>
    );
  }
);
