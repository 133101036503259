import { CSS, styled, useThemeOverrides } from "@truepill/capsule-utils";
import cn from "classnames";
import React from "react";

import type { Spacing } from "../../types";
import { Header } from "../header/Header";
import { Spacer } from "../spacer/Spacer";
import { Text } from "../text/Text";

/**
 * FormGroup props
 */
interface FormGroupProps extends React.HtmlHTMLAttributes<HTMLFieldSetElement> {
  /**
   * Title of the component, it is rendered as a `legend` tag.
   */
  label?: string;
  /**
   * Defines the text below the title.
   */
  subLabel?: string;
  /**
   * Defines the text below the subtitle.
   */
  helperText?: string;
  /**
   * Sets the group as required.
   */
  required?: boolean;
  /**
   * Disable the children of the component.
   */
  disabled?: boolean;
  /**
   * Define the space between each checkbox child.
   */
  spacing?: Spacing;
  /**
   * Additional classnames
   */
  className?: string;
  /**
   * CSS override for the component
   */
  css?: CSS;
}

const StyledFormGroup = styled("fieldset", {
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  border: "none",
  ".required": {
    color: "$functional-error-dark",
    marginLeft: "0.125rem",
    fontSize: "0.875rem",
  },
});

const SubLabel = styled(Text, {
  color: "$typography-medium",
});

const HelperText = styled(Text, {
  color: "$functional-error-dark",
});

/**
  Allows the handling of a group of `Checkbox` components, allowing easy styling.
 */
export const FormGroup = React.forwardRef<HTMLFieldSetElement, FormGroupProps>(
  (
    {
      className,
      helperText,
      label,
      subLabel,
      children,
      required = false,
      spacing = "md",
      disabled,
      css,
      ...rest
    },
    ref
  ) => {
    const styleOverrides = useThemeOverrides("formGroup");
    return (
      <StyledFormGroup
        className={cn("capsule", "form-group", className)}
        disabled={disabled}
        ref={ref}
        css={{ ...css, ...styleOverrides }}
        {...rest}
      >
        <div>
          {label && (
            <>
              <Header as="legend" variant="xl" bold>
                <span data-form-group-label>{label}</span>
                {required && <span className="required">&#42;</span>}
              </Header>
            </>
          )}
          {subLabel && (
            <>
              <Spacer size="sm" />
              <SubLabel variant="body-sm" data-form-group-sublabel>
                {subLabel}
              </SubLabel>
            </>
          )}
          {helperText && (
            <>
              <Spacer size="sm" />
              <HelperText variant="body-sm" data-form-group-helper-text>
                {helperText}
              </HelperText>
            </>
          )}
        </div>
        {(label || subLabel || helperText) && <Spacer size="lg" />}
        <div data-form-group-items>
          {React.Children.map(children, (child, index) => {
            if (!React.isValidElement(child)) {
              return child;
            }
            return (
              <>
                {index > 0 && <Spacer size={spacing} />}
                {disabled ? React.cloneElement(child, { disabled }) : child}
              </>
            );
          })}
        </div>
      </StyledFormGroup>
    );
  }
);
