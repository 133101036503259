import { styled, useThemeOverrides } from "@truepill/capsule-utils";
import cn from "classnames";
import React from "react";
import { ReactElement } from "react";
import { ChevronRight } from "react-feather";

interface BreadcrumbsProps extends React.ComponentPropsWithoutRef<typeof Nav> {
  /**
   * If true, will only show the first and last breadcrumb.
   */
  collapse?: boolean;
}

const Crumbs = styled("ol", {
  display: "flex",
  alignItems: "center",
  listStyle: "none",
  fontSize: "14px",
  "@desktop": {
    fontSize: "$md",
  },
  "& .capsule.text": {
    fontSize: "14px",
    "@desktop": {
      fontSize: "$md",
    },
  },
  variants: {
    collapse: {
      true: {
        "li:not(:first-child):not(:last-child):not(:nth-child(2))": {
          display: "none",
        },
      },
    },
  },
});

const Chevron = styled(ChevronRight, {
  alignSelf: "center",
});

const Crumb = styled("li", {
  display: "flex",
});
const Separator = styled("li", {
  display: "inline-flex",
  margin: "0 0.5rem",
});

const Nav = styled("nav", {});

/**
  Breadcrumbs allow users to make selections from a range of values.
 */
export const Breadcrumbs = ({
  className,
  children,
  collapse = false,
  css,
  ...rest
}: BreadcrumbsProps): ReactElement => {
  const overrides = useThemeOverrides("breadcrumbs");

  return (
    <Nav
      className={cn("capsule", "breadcrumbs", className)}
      css={{ ...(overrides && overrides), ...css }}
      {...rest}
    >
      <Crumbs collapse={collapse}>
        {React.Children.map(children, (child, index) => {
          if (!React.isValidElement(child)) {
            return child;
          }
          return (
            <>
              {index !== 0 && (
                <Separator aria-hidden>
                  <Chevron size="1.2em" />
                </Separator>
              )}
              <Crumb>{child}</Crumb>
            </>
          );
        })}
      </Crumbs>
    </Nav>
  );
};
