import {
  Arrow,
  Content,
  PopoverContentProps,
  Root,
  Trigger,
} from "@radix-ui/react-popover";
import type { CSS } from "@truepill/capsule-utils";
import { keyframes, styled, useThemeOverrides } from "@truepill/capsule-utils";
import React from "react";

type Variant =
  | "white"
  | "brand-mid"
  | "primary"
  | "brand-dark"
  | "brand-light"
  | "secondary";

interface PopoverProps {
  /**
   * The controlled open state of the popover. Must be used in conjunction with onOpenChange.
   */
  open?: boolean;
  /**
   * The open state of the popover when it is initially rendered. Use when you do not need to control its open state..
   */
  defaultOpen?: boolean;
  /**
   * Event handler called when the open state of the popover changes..
   */
  onOpenChange?: (open: boolean) => void;
  /**
   * The modality of the popover. When set to true, interaction with outside elements will be disabled and only popover content will be visible to screen readers.
   */
  modal?: boolean;
}

interface ContentProps extends PopoverContentProps {
  /**
   * Define the color scheme of the popover content.
   */
  variant?: Variant;
  /**
   * The distance in pixels to render from the PopoverContent edge.
   */
  arrowOffset?: number;
  /**
   * Allows the overriding of the default content styles.
   */
  css?: CSS;
  /**
   * Allows the overriding of the default arrow styles.
   */
  arrowCss?: CSS;
}

const StyledArrow = styled(Arrow, {
  variants: {
    variant: {
      white: {
        fill: "$white",
      },
      primary: {
        fill: "$primary-700",
      },
      secondary: {
        fill: "$secondary-700",
      },
      "brand-light": {
        fill: "$primary-300",
      },
      "brand-mid": {
        fill: "$primary-500",
      },
      "brand-dark": {
        fill: "$primary-900",
      },
    },
  },
  defaultVariants: {
    variant: "white",
  },
});

const FadeFromBottom = keyframes({
  "0%": { opacity: 0, transform: "translateY(10px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});
const FadeFromTop = keyframes({
  "0%": { opacity: 0, transform: "translateY(-10px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});
const FadeFromRight = keyframes({
  "0%": { opacity: 0, transform: "translateX(10px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});
const FadeFromLeft = keyframes({
  "0%": { opacity: 0, transform: "translateX(-10px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const StyledPopoverContent = styled(Content, {
  transformOrigin: "var(--radix-popover-content-transform-origin)",
  borderRadius: "$sm",
  boxShadow: "$md",
  padding: "$md",
  animationDuration: "0.2s",
  animationTimingFunction: "ease-in-out",
  willChange: "transform, opacity",
  '&[data-state="open"]': {
    '&[data-side="top"]': { animationName: FadeFromTop },
    '&[data-side="right"]': { animationName: FadeFromRight },
    '&[data-side="bottom"]': { animationName: FadeFromBottom },
    '&[data-side="left"]': { animationName: FadeFromLeft },
  },
  variants: {
    variant: {
      white: {
        color: "$typography-dark",
        backgroundColor: "$white",
      },
      primary: {
        color: "$typography-white",
        backgroundColor: "$primary-700",
      },
      secondary: {
        color: "$typography-white",
        backgroundColor: "$secondary-700",
      },
      "brand-light": {
        color: "$typography-dark",
        backgroundColor: "$primary-300",
      },
      "brand-mid": {
        color: "$typography-white",
        backgroundColor: "$primary-500",
      },
      "brand-dark": {
        color: "$typography-white",
        backgroundColor: "$primary-900",
      },
    },
  },
  defaultVariants: {
    variant: "white",
  },
});

/**
 * The root component for the popover element.
 */
export const Popover: React.FC<PopoverProps> = Root;

/**
 * The button that toggles the popover.
 * @param asChild - Change the component to the HTML tag or custom component of the only child. This will merge the original component props with the props of the supplied element/component and change the underlying DOM node.
 */
export const PopoverTrigger = styled(Trigger, {});

/**
 * The component that pops out when the popover is open.
 */
export const PopoverContent = React.forwardRef<
  React.ElementRef<typeof StyledPopoverContent>,
  ContentProps
>(
  (
    { children, variant, arrowOffset = 6, css, arrowCss, ...props },
    forwardedRef
  ) => {
    const contentOverrides = useThemeOverrides("popoverContent");
    const arrowOverrides = useThemeOverrides("popoverArrow");

    return (
      <StyledPopoverContent
        sideOffset={5}
        css={{ ...(contentOverrides && contentOverrides), ...css }}
        {...props}
        variant={variant}
        ref={forwardedRef}
      >
        {children}
        <StyledArrow
          css={{ ...(arrowOverrides && arrowOverrides), ...arrowCss }}
          offset={arrowOffset}
          variant={variant}
          width={16}
          height={12}
        />
      </StyledPopoverContent>
    );
  }
);
