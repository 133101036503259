import { styled, useThemeOverrides } from "@truepill/capsule-utils";
import * as React from "react";

import { Text } from "../text/Text";

type AvatarProps = DefaultProps | WithImage;

type AvatarWithoutInitials = Omit<
  React.ComponentPropsWithoutRef<typeof Wrapper>,
  "initials"
>;

interface DefaultProps extends AvatarWithoutInitials {
  /**
   * Initials to be displayed in the Avatar. Ignored if image is provided.
   */
  initials?: string;
  /**
   * src for img to be displayed in Avatar. Requires an alt prop.
   */
  image?: never;
  /**
   * alt to be added to img shown in Avatar.
   */
  alt?: never;
}

interface WithImage extends AvatarWithoutInitials {
  initials?: never;
  image: string;
  alt: string;
}

const createSizeVariant = (size: string) => {
  return {
    "$$cap-avatar-size": size,
    size: "$$cap-avatar-size",
    [`& ${Icon}`]: {
      size: "calc($$cap-avatar-size / 2)",
    },
  };
};

const Icon = styled("svg", {
  size: "32px",
  color: "$gray-700",
});

const Wrapper = styled("div", {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "$xl",
  background: "$gray-300",
  variants: {
    initials: {
      true: {
        background: "$primary-700",
      },
    },
    size: {
      xs: createSizeVariant("32px"),
      sm: createSizeVariant("48px"),
      md: createSizeVariant("56px"),
      lg: createSizeVariant("64px"),
    },
  },
  defaultVariants: {
    size: "md",
  },
});
const Initials = styled("span", {
  whiteSpace: "nowrap",
  color: "$typography-white",
});

const Image = styled("img", {
  size: "100%",
  borderRadius: "$xl",
});

const AvatarChildren = ({
  src,
  alt,
  initials,
}: {
  src?: string;
  alt?: string;
  initials?: string;
}) => {
  if (src) {
    return <Image src={src} alt={alt} />;
  }
  if (initials) {
    return (
      <Initials>
        <Text bold as="span">
          {initials}
        </Text>
      </Initials>
    );
  }

  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      data-test-id="cap-avatar-icon"
    >
      <title>User</title>
      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
      <circle cx="12" cy="7" r="4"></circle>
    </Icon>
  );
};

/**
 * Display users profile image. Also handles fallbacks.
 * @param size - The size of the Avatar. Default `md`
 * @param initials - Initials to be displayed in Avatar. This will be ignored if a image prop is passed.
 * @param image - src for an img to be displayed in the Avatar. Requires an alt prop
 * @param alt - alt to be attached to the img tag when image is passed.
 */
export const Avatar = React.forwardRef<
  React.ElementRef<typeof Wrapper>,
  AvatarProps
>(({ initials, image, alt, css, ...rest }, forwardedRef) => {
  const overrides = useThemeOverrides("avatar");

  return (
    <Wrapper
      initials={!!initials}
      ref={forwardedRef}
      css={{ ...(overrides && overrides), ...css }}
      {...rest}
    >
      <AvatarChildren src={image} initials={initials} alt={alt} />
    </Wrapper>
  );
});
