import Alert from "@reach/alert";
import { CSS, styled, useThemeOverrides } from "@truepill/capsule-utils";
import cn from "classnames";
import React, { HTMLAttributes, ReactElement } from "react";
import { useEffect } from "react";
import {
  AlertOctagon,
  AlertTriangle,
  CheckCircle,
  Info,
  X,
} from "react-feather";

import { ScreenReaderOnly } from "../screen-reader-only/ScreenReaderOnly";

export type BannerStateNoError = "info" | "success" | "warning";
export type BannerState = BannerStateNoError | "error";

/**
 * BannerAlert props
 */
interface BannerAlertProps extends HTMLAttributes<HTMLDivElement> {
  /** define the styling of banner.*/
  state?: BannerStateNoError;
  /** callback function which runs when a user clicks the dismiss button on the banner. if error state is chosen, this will have no effect.*/
  onDismiss?: () => void;
  /**  Set the time between the banner showing and hiding. Will not work if onTimeout is not defined. if error state is chosen, this will have no effect.*/
  timeout?: number;
  /** callback function which runs when the timeout is complete. if error state is chosen, this will have not effect.*/
  onTimeout?: () => void;
  /** is the banner visible.*/
  visible?: boolean;
  css?: CSS;
}
interface BannerDangerProps extends HTMLAttributes<HTMLDivElement> {
  state: "error";
  onDismiss?: never;
  timeout?: never;
  onTimeout?: never;
  visible?: boolean;
  css?: CSS;
}

const StyledBanner = styled("div", {
  display: "flex",
  alignItems: "flex-start",
  borderRadius: "$sm",
  border: "1px solid $$banner-border",
  borderLeft: "4px solid $$banner-border",
  background: "$$banner-background",
  color: "$$banner-color",
  padding: "1.5rem",
  "svg, a": {
    color: "$$banner-color",
  },
  variants: {
    bannerState: {
      info: {
        "$$banner-color": "$colors$functional-info-dark",
        "$$banner-background": "$colors$functional-info-light",
        "$$banner-border": "$colors$functional-info-dark",
      },
      warning: {
        "$$banner-color": "$colors$functional-warning-dark",
        "$$banner-background": "$colors$functional-warning-light",
        "$$banner-border": "$colors$functional-warning-dark",
      },
      success: {
        "$$banner-color": "$colors$functional-success-dark",
        "$$banner-background": "$colors$functional-success-light",
        "$$banner-border": "$colors$functional-success-dark",
      },
      error: {
        "$$banner-color": "$colors$functional-error-dark",
        "$$banner-background": "$colors$functional-error-light",
        "$$banner-border": "$colors$functional-error-dark",
      },
    },
  },
});

const BannerContent = styled("div", {
  padding: "0 0 0 1rem",
  flex: 1,
  alignSelf: "center",
});

const Dismiss = styled("button", {
  background: "none",
  border: "none",
  cursor: "pointer",
  padding: 0,
});

const iconMap: Record<BannerState, ReactElement> = {
  info: <Info aria-hidden />,
  success: <CheckCircle aria-hidden />,
  warning: <AlertTriangle aria-hidden />,
  error: <AlertOctagon aria-hidden />,
};
/**
  Provide users with contextual information related to their current activity.
 */
export const BannerAlert = React.forwardRef<
  React.ElementRef<typeof StyledBanner>,
  BannerAlertProps | BannerDangerProps
>(
  (
    {
      className,
      state = "info",
      children,
      onDismiss,
      timeout = 5000,
      onTimeout,
      visible = true,
      css,
      ...rest
    },
    forwardedRef
  ) => {
    useEffect(() => {
      if (!onTimeout || !visible) return;
      const timeoutId = setTimeout(onTimeout, timeout);
      return () => clearTimeout(timeoutId);
    }, [onTimeout, timeout, visible]);
    const nonErrorStates = ["info", "success", "warning"];
    const hasDismissButton = nonErrorStates.includes(state) && !!onDismiss;

    const overrides = useThemeOverrides("bannerAlert");

    if (!visible) return <></>;
    return (
      <Alert
        as={StyledBanner}
        className={cn("capsule", "banner", className)}
        bannerState={state}
        css={{ ...(overrides && overrides), ...css }}
        ref={forwardedRef}
        {...rest}
      >
        {iconMap[state]}
        <BannerContent>{children}</BannerContent>
        {hasDismissButton && (
          <Dismiss type="button" onClick={onDismiss}>
            <X aria-hidden />
            <ScreenReaderOnly>Dismiss</ScreenReaderOnly>
          </Dismiss>
        )}
      </Alert>
    );
  }
);
