import { CSS, styled, useThemeOverrides } from "@truepill/capsule-utils";
import type { ReactElement, ReactNode } from "react";
import React from "react";
import { X } from "react-feather";

import { ScreenReaderOnly } from "../screen-reader-only/ScreenReaderOnly";
import { Text } from "../text/Text";

type state = "primary" | "info" | "warning" | "error" | "success";
type size = "sm" | "lg";

interface ChipProps {
  /** Determine the color variant being shown. Defaults to `primary`. */
  state?: state;
  /** If true, adds a border to Chip.*/
  outline?: boolean;
  /** Set the size of Chip. defaults to `sm`. */
  size?: size;
  /** If passed, will render a X button inside chip. Clicking this button calls `onDismiss` */
  onDismiss?: () => void;
  children?: ReactNode;
  css?: CSS;
}

const ChipWrapper = styled("div", {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "100px",
  variants: {
    size: {
      sm: {
        height: "24px",
      },
      lg: {
        height: "32px",
      },
    },
    outline: {
      true: {
        border: "1px solid",
      },
    },
    state: {
      primary: {
        color: "$primary-700",
        backgroundColor: "$primary-100",
        borderColor: "$primary-700",
      },
      info: {
        color: "$functional-info-dark",
        backgroundColor: "$functional-info-light",
        borderColor: "$primary-700",
      },
      warning: {
        color: "$functional-warning-dark",
        backgroundColor: "$functional-warning-light",
        borderColor: "$functional-warning-dark",
      },
      error: {
        color: "$functional-error-dark",
        backgroundColor: "$functional-error-light",
        borderColor: "$functional-error-dark",
      },
      success: {
        color: "$functional-success-dark",
        backgroundColor: "$functional-success-light",
        borderColor: "$functional-success-dark",
      },
    },
  },
  defaultVariants: {
    size: "sm",
    outline: false,
    state: "primary",
  },
});

const ChipContent = styled(Text, {
  paddingLeft: "0.5rem",
  paddingRight: "0.5rem",
});

const CloseButton = styled("button", {
  marginRight: "0.5rem",
  marginLeft: "-0.25rem",
  size: "1rem",
  padding: "0",
  background: "none",
  border: "none",
  color: "inherit",
  cursor: "pointer",
  position: "relative",
  top: 1,
});

/**
 * A chip component themed to the style of Capsule.
 */
export const Chip = React.forwardRef<HTMLDivElement, ChipProps>(
  (
    {
      children,
      state = "primary",
      outline,
      size = "sm",
      onDismiss,
      css,
      ...rest
    },
    ref
  ): ReactElement => {
    const themeOverrides = useThemeOverrides("chip");
    return (
      <ChipWrapper
        state={state}
        outline={outline}
        size={size}
        ref={ref}
        css={{ ...css, ...themeOverrides }}
        {...rest}
      >
        <ChipContent state="chip">{children}</ChipContent>
        {onDismiss && (
          <CloseButton onClick={onDismiss}>
            <X size={16} />
            <ScreenReaderOnly>Dismiss</ScreenReaderOnly>
          </CloseButton>
        )}
      </ChipWrapper>
    );
  }
);
