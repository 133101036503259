import { CSS, styled, useThemeOverrides } from "@truepill/capsule-utils";
import cn from "classnames";
import React, { ReactElement } from "react";

/**
 * ProgressBar props
 */

type Sizes = "sm" | "md";

interface ProgressBarProps {
  /**
   * Additional classnames
   */
  className?: string;
  /**
   * The size of the progress bar
   */
  size?: Sizes;
  /**
   * The maximum amount the progress bar can reach.
   */
  max?: number;
  /**
   * The current value the bar is at.
   */
  value: number;
  css?: CSS;
}

const StyledProgressBar = styled("div", {
  position: "relative",
  width: "100%",
  borderRadius: "100px",
  overflow: "hidden",
  variants: {
    size: {
      sm: {
        height: "0.25rem",
      },
      md: {
        height: "0.5rem",
      },
    },
  },
  defaultVariants: {
    size: "md",
  },
});

const Bar = styled("div", {
  position: "absolute",
  borderRadius: "100px",
  backgroundColor: "$primary-700",
  height: "100%",
  top: "0",
});

const Background = styled("div", {
  size: "100%",
  position: "absolute",
  top: "0",
  backgroundColor: "$gray-300",
});

/**
  An element that displays the progress status for tass that take a long time.
 *
 * @param size - size of the button. Defaults to `md`
 * @param max - the maximum value the progress bar can reach. Defaults to `100`
 * @param value - Current value of the progress bar. Should not be more than max.
 *
 * @beta
 */

export const ProgressBar = React.forwardRef<HTMLDivElement, ProgressBarProps>(
  ({ className, size, max = 100, value, css, ...rest }, ref): ReactElement => {
    const progressBarOverrides = useThemeOverrides("progressBar");

    return (
      <StyledProgressBar
        className={cn("capsule", "progress-bar", className)}
        role="progressbar"
        aria-valuenow={value}
        aria-valuemin={0}
        aria-valuemax={max}
        size={size}
        ref={ref}
        css={{ ...progressBarOverrides, ...css }}
        {...rest}
      >
        <Background className={cn("capsule", "progress-background")} />
        <Bar
          className={cn("capsule", "progress-bar")}
          style={{ width: `${Math.floor((value / max) * 100)}%` }}
        />
      </StyledProgressBar>
    );
  }
);
